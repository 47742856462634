<template>
    <div class="block" style="width: 100%;">
        <div style="width: 140px;height:140px;border: 1px solid #DCDFE6;float: left">
            <el-image :src="statusUrl" :preview-src-list="[statusUrl]" style="width: 100px; height: 100px;padding: 20px;"/>
        </div>
    </div>
</template>
<script>
export default {
    props: ["value"],
    data() {
        return {
            statusUrl: this.$props.value
        };
    },
    watch: {
        value(newVal) {
            this.statusUrl = newVal;
        }
    }
}
</script>
