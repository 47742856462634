<template>
    <el-upload class="upload-demo" drag :before-upload="beforeAvatarUpload" :on-change="handleChange" :auto-upload="false" :show-file-list="false" :multiple="false" list-type="picture" action="" >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将视频文件拖到此处，或<em>点击上传</em></div>
    </el-upload>
    <div class="el-upload__text">只能上传 {{uploadExt}} 文件</div>
    <ul class="el-upload-list el-upload-list--picture">
        <li v-for="item in ossFileList" :key="item.url" class="el-upload-list__item is-ready" tabindex="0">
            <span v-if="item.videoUrl">
                <img @click="showDialog(item.url,item.videoUrl)" class="el-upload-list__item-thumbnail" :src="item.url" alt="图片" >
                <span style="font-size:14px;">{{item.name}} <i class="el-icon-video-camera" style="color: #20a0ff;"></i></span>
            </span>
            <span v-else>
                <el-image :src="item.url" :preview-src-list="[item.url]" class="el-upload-list__item-thumbnail"/>
                <span style="font-size:14px;">{{item.name}} <i class="el-icon-picture-outline"  style="color: #20a0ff;"></i></span>
            </span>

            <i @click="ossFileDel(item.url)" class="el-icon-close"></i>
        </li>
    </ul>
    <div style="margin: 10px 0;width: 100%;">
        <el-progress :percentage="percentage" :color="colors" :text-inside="true" :stroke-width="20" v-if="(percentage > 0)" style="width: 100%;"></el-progress>
    </div>
    <el-dialog v-model="ossFileShow" :close-on-click-modal="false">
        <video controls="controls" :src="ossFileVideoUrl" style="height: 500px;width: 100%;"/>
    </el-dialog>
</template>

<script>
import {apiCall, apiUrlEnums} from "@/api/requestApi";
import OSS from "ali-oss"
import baseUtils from "@/utils/baseUtils";

export default {
    props: ["dataUrlList","dataExtStr","dataUrlNum"],
    emits: ["successHandle", "failHandle"],
    data () {
        return {
            ossFileUrl: "",
            ossFileVideoUrl: "",
            ossFileShow: false,
            clientObject: null,
            uploadFile: null,
            uploadUrlNum: 0,
            uploadExt: "jpg,png,jpeg,mp4",
            percentage: 0,
            ossResult: {},
            ossFileList: [],//this.$props.dataUrlList,//[{name: "food.jpeg", url: "http://zmt-video.oss-cn-chengdu.aliyuncs.com/ecb4d26b-5ea5-4fd7-475b-e572a43ce597.mp4", videoUrl: "http://zmt-video.oss-cn-chengdu.aliyuncs.com/ecb4d26b-5ea5-4fd7-475b-e572a43ce597.mp4"}],
            colors: [
                {color: "#f80808", percentage: 20},
                {color: "#d24040", percentage: 40},
                {color: "#0c26f3", percentage: 60},
                {color: "#e27d11", percentage: 80},
                {color: "#13d753", percentage: 100}
            ]
        }
    },
    methods: {
        /**
         * 显示文件
         * @param url
         * @param videoUrl
         * @returns {boolean|string}
         */
        showDialog(url,videoUrl){
            this.ossFileShow = true;
            this.ossFileUrl = url;
            this.ossFileVideoUrl = videoUrl;
        },

        /**
         * 删除图片列表
         * @param url
         * @returns {boolean|string}
         */
        ossFileDel(url){
            this.ossFileList = this.ossFileList.filter((item) =>item["url"] !== url);
            let fileListTemp = [];
            if(this.ossFileList && this.ossFileList.length > 0){
                this.ossFileList.forEach(function(item){
                    if(item.videoUrl){
                        fileListTemp.push(item.videoUrl);
                    }else{
                        fileListTemp.push(item.url);
                    }
                })
            }
            this.$emit('successHandle', fileListTemp);
        },

        /**
         * 取得文件扩展名
         * @param url
         * @returns {boolean|string}
         */
        getFileExt(url){
            return (url.substr(url.lastIndexOf(".")+1));
        },

        /**
         * 检测上传文件格式及大小
         * @param file
         * @returns {boolean|string}
         */
        beforeAvatarUpload(file) {
            if(this.ossFileList) {
                if (this.ossFileList.length >= this.uploadUrlNum && this.uploadUrlNum !== 0){
                    this.$message.error("最多只能上传" + this.uploadUrlNum + "个文件！");
                    return false;
                }
            }else{
                this.ossFileList = [];
            }

            const fileExt = this.getFileExt(file.name);
            if(this.uploadExt.indexOf(fileExt) === -1){
                this.$message.error("只能上传"+this.uploadExt+"格式文件！");
                return false;
            }

            if (file.size > 1024 * 1024 * 1000) {
                this.$message.error("上传文件不能超过10MB!");
                return false;
            }else{
                this.ossResult.ossSize = baseUtils.renderSize(file.size);
            }

            return fileExt;
        },

        /**
         * 分片上传文件
         * @returns {Promise<void>}
         */
        async multipartUpload () {
            let self = this;
            if (this.uploadFile && this.clientObject) {
                const beforeAvatarUpload = this.beforeAvatarUpload(this.uploadFile);
                if(beforeAvatarUpload){
                    try {
                        this.clientObject.multipartUpload(baseUtils.getGuid() +"."+ beforeAvatarUpload, this.uploadFile, {
                            progress:(val) => {
                                self.percentage = parseFloat((val*100).toFixed(2));
                            },
                            partSize: 1024 * 100,//设置分片大小，100K
                            timeout: 120000,//设置超时时间,2分钟
                        }).then(function(res) {
                            self.ossResult.ossKey = ((res.res.requestUrls[0]).split("?")[0]);
                            setTimeout(() => {
                                self.percentage = 0;
                                let urlInfo = baseUtils.getUrlInfo(self.ossResult.ossKey);
                                if(urlInfo && urlInfo.fileExt.toLowerCase() === "mp4") {
                                    self.ossFileList.push({name:self.uploadFile.name,url:self.ossResult.ossKey + "?x-oss-process=video/snapshot,t_1,w_100,h_100,m_fast",videoUrl:self.ossResult.ossKey});
                                }else{
                                    self.ossFileList.push({name:self.uploadFile.name,url:self.ossResult.ossKey});
                                }

                                let fileListTemp = [];
                                if(self.ossFileList && self.ossFileList.length > 0){
                                    self.ossFileList.forEach(function(item){
                                        if(item.videoUrl){
                                            fileListTemp.push(item.videoUrl);
                                        }else{
                                            fileListTemp.push(item.url);
                                        }
                                    })
                                }
                                self.$emit('successHandle', fileListTemp);
                            }, 1500);
                        }).catch(function(err) {
                            console.log(err)
                            self.$message.error("云服务器错误提示："+err.message);
                            self.$emit("failHandle",err.message);
                        });
                    } catch (e) {
                        self.$emit("failHandle",e.message);
                    }
                }
            }else{
                if(!this.clientObject){
                    self.$message.error("正在获取云服务器授权，请稍后重试......");
                }
            }
        },

        /**
         * 获取上传文件所需的OSS配置信息
         */
        getClient(){
            apiCall(apiUrlEnums.getOssSts, {}).then(res => {
                if (res.code === 0) {
                    this.clientObject = new OSS({
                        region: res.region, //根据那你的Bucket地点来填写
                        secure: true,//是否启用HTTPS
                        accessKeyId: res.accessKeyId, //自己账户的accessKeyId
                        accessKeySecret: res.accessKeySecret, //自己账户的accessKeySecret
                        stsToken:res.stsToken, //从STS服务获取的安全令牌（SecurityToken）
                        bucket: res.bucket, //bucket名字
                        refreshSTSTokenInterval: 60000,//刷新临时访问凭证的时间间隔，单位为毫秒。
                        refreshSTSToken: async () => {
                            return {
                                accessKeyId: res.accessKeyId,
                                accessKeySecret: res.accessKeySecret,
                                stsToken: res.stsToken
                            }
                        },
                    });
                } else {
                    this.$message({showClose: true,message: "获取云服务器上传授权异常！",type: "warning"});
                }
            });
        },

        /**
         * 上传组件内容变更触发
         * @param file
         */
        handleChange(file) {
            this.percentage = 0;
            this.uploadFile = file.raw;
            this.multipartUpload();
        },
    },
    watch: {
        dataUrlList:{
            immediate: true,
            handler(urlList) {
                if(urlList && urlList.length > 0) {
                    let self = this;
                    this.ossFileList = [];
                    urlList.forEach(function(item){
                        let urlInfo = baseUtils.getUrlInfo(item);
                        if(urlInfo && urlInfo.fileExt.toLowerCase() === "mp4") {
                            self.ossFileList.push({name: urlInfo.fileName,url: item + "?x-oss-process=video/snapshot,t_1,w_100,h_100,m_fast",videoUrl: item,});
                        }else{
                            if(urlInfo){
                                self.ossFileList.push({name: urlInfo.fileName,url: item});
                            }else{
                                self.ossFileList.push({name: item,url: item});
                            }
                        }
                    });
                }
            }
        },
        dataExtStr:{
            immediate: true,
            handler(val) {
                if(val) {
                    this.uploadExt = val;
                }
            }
        },
        dataUrlNum:{
            immediate: true,
            handler(val) {
                if(val) {
                    this.uploadUrlNum = val;
                }
            }
        },
    },
    created() {
        this.getClient();
    },
}
</script>
<style scoped>
::v-deep .el-upload-list__item .el-icon-close{
    display:inline;
}
</style>
