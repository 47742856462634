<template>
	<el-row style="width: 100%;">
		<el-col :span="18">
			<el-row style="width: 100%;">
				<el-col :span="6" v-for="item in walletTotalList" :key="item" style="padding: 20px;">
					<el-card class="box-card" style="width: 100%;">
						<el-row style="width: 100%;">
							<el-col :span="24" style="width: 100%;">
								<ul style="list-style: none;width: 100%;">
									<li style="font-size: 12px;font-weight: bold;text-align:left;">{{ item.title }}</li>
									<li
										style="font-size: 18px;font-weight: bold;text-align:center;margin-top: 10px;margin-bottom: 10px;color: rgba(56, 148, 255, 1);">
										{{ onNumberToCurrency(item.num) }}</li>
									<li style="font-size: 12px;text-align:left;">{{ item.subTitle }}：<span
											style="font-weight: bold;color: rgba(252, 163, 74, 1);">{{ item.subNum }}</span></li>
								</ul>
							</el-col>
						</el-row>
					</el-card>
				</el-col>
			</el-row>
		</el-col>
		<el-col :span="6">
			<el-row style="width: 100%;padding: 20px;">
				<el-button v-if="onRoleFunc(28)" type="primary" @click="onWalletWithdrawApply"
					style="width: 80%;padding: 10px;">提现</el-button>
				<el-button v-if="onRoleFunc(29) || onRoleFunc(30) || onRoleFunc(31)" type="info" @click="onWalletWithdrawBank"
					style="margin-left:0;margin-top:20px;width: 80%;padding: 10px;"> 收款账户 </el-button>
			</el-row>
		</el-col>
	</el-row>
	<el-row style="width: 100%;">
		<el-tabs v-model="walletActive" style="margin-left: 20px;margin-right: 20px;width: 100%;">
			<el-tab-pane label="提现明细" name="1">
				<el-table fit highlight-current-row border :data="walletWithdrawList" :height="walletTableHeight"
					style="width: 100%">
					<el-table-column prop="created_at" label="申请时间" width="140" :formatter="onDateTimeFormat" />
					<el-table-column prop="agent_withdraw_account_card" label="卡号" />
					<el-table-column prop="bank.bank_name" label="银行" />
					<el-table-column prop="agent_withdraw_account_hold" label="持卡人" />
					<el-table-column prop="agent_apply_money" label="金额（元）" :formatter="onNumberToCurrencyFormat" />
					<el-table-column prop="payTime" label="审核状态" width="140">
						<template #default="scope">
							{{ protectStatusNameArray[scope.row.agent_withdraw_status] }}
						</template>
					</el-table-column>
					<el-table-column prop="created_admin" label="申请人" />
					<el-table-column label="操作" width="120" v-if="onRoleFunc(37)">
						<template #default="scope">
							<el-button type="text" @click="onWalletInfo(scope.row)"> 查看详情 </el-button>
						</template>
					</el-table-column>
				</el-table>
				<div class="tablePage">
					<el-row :gutter="24">
						<el-col :span="24">
							<el-pagination :currentPage="walletWithdrawSearch.page" :page-sizes="[20, 50, 100, 200, 500]"
								:page-size="walletWithdrawSearch.pageSize" :total="walletWithdrawTotal"
								layout="total, sizes, prev, pager, next, jumper" @size-change="onWalletWithdrawSizeChange"
								@current-change="onWalletWithdrawCurrentChange"></el-pagination>
						</el-col>
					</el-row>
				</div>
			</el-tab-pane>
			<el-tab-pane label="收益明细" name="2">
				<el-table fit highlight-current-row border :data="walletProfitList" :height="walletTableHeight"
					style="width: 100%">
					<el-table-column prop="nickname" label="用户" width="140" />
					<el-table-column prop="mobile" label="手机号" />
					<el-table-column prop="pay_money" label="金额（元）" :formatter="onNumberToCurrencyFormat" />
					<el-table-column prop="order_no" label="订单" />
					<el-table-column prop="paid_money" label="收益金额（元）" />
					<el-table-column prop="paid_at" label="收益时间" width="140" />
				</el-table>
				<div class="tablePage">
					<el-row :gutter="24">
						<el-col :span="24">
							<el-pagination :currentPage="walletProfitSearch.page" :page-sizes="[20, 50, 100, 200, 500]"
								:page-size="walletProfitSearch.pageSize" :total="walletProfitTotal"
								layout="total, sizes, prev, pager, next, jumper" @size-change="onWalletProfitSizeChange"
								@current-change="onWalletProfitCurrentChange"></el-pagination>
						</el-col>
					</el-row>
				</div>
			</el-tab-pane>
		</el-tabs>
	</el-row>

	<el-drawer title="提现" size="30%" v-model="walletWithdrawApplyShow" direction="rtl" destroy-on-close>
		<el-form :model="walletWithdrawApplyData" :rules="walletWithdrawApplyRules" ref="walletWithdrawApplyFrom"
			label-width="120px">
			<el-form-item label="可提金额：" style="font-size: 16px;font-weight: bold;">{{ walletTotalData["agent_sure_withdrawal"]
			}}</el-form-item>
			<el-form-item label="提现金额：" prop="walletWithdrawAmount">
				<el-input v-model="walletWithdrawApplyData.walletWithdrawAmount" @keyup="onArrivalAmount"
					style="width: 220px;" />
				<!-- <span style="float: left;width: 100%;color: #a8a8a8;">*提现金额为百的整数倍，会扣除1%的手续费</span> -->
				<span style="float: left;width: 100%;color: #a8a8a8;">*提现会扣除1%的手续费</span>
			</el-form-item>
			<el-form-item label="预计到账金额：" style="font-size: 16px;font-weight: bold;">{{
					walletWithdrawApplyData.walletWithdrawArrivalAmount
			}}</el-form-item>
			<el-form-item label="提现至：" prop="walletWithdrawBank">
				<el-select v-model="walletWithdrawApplyData.walletWithdrawBank" placeholder="请选择">
					<el-option v-for="item in walletWithdrawAccountList" :key="item.agent_withdraw_account_id"
						:label="item.agent_withdraw_account_card" :value="item.agent_withdraw_account_id"></el-option>
				</el-select>
				<!-- <el-button type="text" icon="el-icon-plus">新增银行卡</el-button> -->
			</el-form-item>
			<el-form-item label="上传发票：" prop="walletWithdrawBill">
				<v-upload :data-url-list="walletWithdrawApplyData.walletWithdrawBill" data-url-num=1
					@successHandle="onVideoUploadSuccess" @failHandle="onVideoUploadFail"></v-upload>
			</el-form-item>
		</el-form>

		<div style="width: 100%;height: 62px;display: flex;justify-content: center;align-items: center;">
			<el-button type="primary" @click="onWalletWithdrawApplySubmit">提交</el-button>
			<el-button @click="walletWithdrawApplyShow = false">取消</el-button>
		</div>
	</el-drawer>

	<el-drawer title="收款账户列表" size="30%" v-model="walletWithdrawBankShow" direction="rtl" destroy-on-close>
		<div :style="{ height: walletAccountHeight, 'overflow-y': 'auto' }">
			<el-card v-for="item in walletWithdrawAccountList" :key="item.agent_withdraw_account_id" style="margin: 5px;">
				<el-row>
					<el-col :span="18">
						<ul style="width: 100%;list-style: none;font-size: 13px;">
							<li><span style="font-weight: bold;">持卡人：</span>{{ item.agent_withdraw_account_hold }}</li>
							<li><span style="font-weight: bold;">卡号：</span>{{ item.agent_withdraw_account_card }}</li>
							<li><span style="font-weight: bold;">银行：</span>{{ item.bank.bank_name }}</li>
						</ul>
					</el-col>
					<el-col :span="6">
						<i v-if="onRoleFunc(30)" @click="onWalletWithdrawBankEdit(item)" class="el-icon-edit"
							style="font-size: 16px;margin:0 10px;"></i>
						<i v-if="onRoleFunc(31)" @click="onWalletWithdrawBankDel(item.agent_withdraw_account_id)"
							class="el-icon-delete" style="font-size: 16px;margin:0 10px;"></i>
					</el-col>
				</el-row>
			</el-card>

			<el-button v-if="onRoleFunc(29)" type="text" @click="onWalletWithdrawBankAdd" icon="el-icon-plus"
				style="font-weight:bold;margin: 20px 0 20px 5px;">新增银行卡</el-button>

			<el-card v-if="walletWithdrawBankAddShow || walletWithdrawBankEditShow" style="margin: 5px;">
				<el-form :model="walletWithdrawBankData" :rules="walletWithdrawBankRules" ref="walletWithdrawBankFrom"
					label-width="120px">
					<el-form-item>
						<span v-if="walletWithdrawBankData.bankAccountId" style="color: red;">*编辑收款账户</span>
						<span v-else style="color: red;">*添加收款账户</span>
					</el-form-item>
					<el-form-item label="持卡人：" prop="bankUser">
						<el-input v-model="walletWithdrawBankData.bankUser" />
					</el-form-item>
					<el-form-item label="卡号：" prop="bankAccount">
						<el-input v-model="walletWithdrawBankData.bankAccount" />
					</el-form-item>
					<el-form-item label="银行：" prop="bankId">
						<el-select v-model="walletWithdrawBankData.bankId" placeholder="请选择">
							<el-option v-for="item in walletBankList" :key="item.bank_id" :label="item.bank_name"
								:value="item.bank_id"></el-option>
						</el-select>
					</el-form-item>
				</el-form>

			</el-card>

			<div v-if="walletWithdrawButtonFlg"
				style="width: 100%;height: 62px;display: flex;justify-content: center;align-items: center;">
				<el-button type="primary" @click="onWalletWithdrawBankAddSubmit">提交</el-button>
				<el-button @click="walletWithdrawBankShow = false">取消</el-button>
			</div>
		</div>
	</el-drawer>

	<el-drawer title="提现详情" size="30%" v-model="walletWithdrawInfoShow" direction="rtl" destroy-on-close>
		<el-form label-width="120px">
			<el-form-item label="发起时间：">{{ onDateTimeFormatStr(walletWithdrawInfoData.created_at) }}</el-form-item>
			<el-form-item label="申请人：">{{ walletWithdrawInfoData.created_admin }}</el-form-item>
			<el-form-item label="提现金额：">{{ walletWithdrawInfoData.agent_apply_money }}</el-form-item>
			<el-form-item label="预警到账金额：">{{ walletWithdrawInfoData.agent_apply_money * 0.99 }}</el-form-item>
			<el-form-item label="提现卡号：">{{ walletWithdrawInfoData.agent_withdraw_account_card }}</el-form-item>
			<el-form-item label="提现银行：">{{ walletWithdrawInfoData.bank.bank_name }}</el-form-item>
			<el-form-item label="电子发票：">
				<v-img-dialog v-if="walletWithdrawInfoData.agent_withdraw_url"
					:value="walletWithdrawInfoData.agent_withdraw_url" />
				<span v-else>无</span>
			</el-form-item>
			<el-form-item label="审批结果：">{{ protectStatusNameArray[walletWithdrawInfoData.agent_withdraw_status]
			}}</el-form-item>
			<el-form-item label="驳回原因：" v-if="walletWithdrawInfoData.agent_withdraw_status === 3">{{
					walletWithdrawInfoData.agent_verify_reject
			}}</el-form-item>
		</el-form>
	</el-drawer>
</template>

<script>
import formatUtils from "@/utils/formatUtils";
import vUpload from "@/components/assembly/vUpload";
import vImgDialog from "@/components/assembly/vImgDialog";
import { apiCall, apiUrlEnums } from "@/api/requestApi";
import baseUtils from "@/utils/baseUtils";

export default {
	emits: ["loading", "callback"],
	components: {
		vUpload,
		vImgDialog,
	},
	data() {
		return {
			protectStatusNameArray: { 1: "待审核", 2: "已提现", 3: "已驳回" },
			walletAccountHeight: ((baseUtils.getClientHeight() - 100) + "px"),
			walletTableHeight: baseUtils.getTableHeight(450),
			walletActive: "1",
			walletTotalList: [],
			walletTotalData: {
				agent_sure_withdrawal: 0
			},

			walletWithdrawList: [],
			walletWithdrawTotal: 0,
			walletWithdrawSearch: {
				page: 1,
				pageSize: 20,
			},

			walletProfitList: [],
			walletProfitTotal: 0,
			walletProfitSearch: {
				page: 1,
				pageSize: 20,
			},

			walletWithdrawButtonFlg: false,
			walletWithdrawApplyShow: false,
			walletWithdrawInfoShow: false,
			walletWithdrawInfoData: {},
			walletWithdrawAccountList: [],
			walletWithdrawApplyData: {
				walletWithdrawAmount: 0,
				walletWithdrawArrivalAmount: 0,
				walletWithdrawBank: "",
				walletWithdrawBill: "",
			},
			walletWithdrawApplyRules: {
				walletWithdrawAmount: [{ required: true, message: "请输入提现金额", trigger: "blur" },
					// {pattern: /^[1-9]\d*00$/, message: "金额必须为百的整数倍", trigger: "blur"}
				],
				walletWithdrawBank: [{ required: true, message: "请选择提现至那个银行账户", trigger: "blur" }],
				walletWithdrawBill: [{ required: true, message: "请上传发票", trigger: "blur" }],
			},

			walletWithdrawBankShow: false,
			walletWithdrawBankAddShow: false,
			walletWithdrawBankEditShow: false,
			walletBankList: [],
			walletWithdrawBankData: {
				bankId: "",
				bankAccount: "",
				bankUser: "",
			},
			walletWithdrawBankRules: {
				bankId: [{ required: true, message: "请选择银行", trigger: "blur" }],
				bankAccount: [{ required: true, message: "请输入卡号", trigger: "blur" }],
				bankUser: [{ required: true, message: "请输入持卡人", trigger: "blur" }],
			},
		};
	},
	methods: {
		onWalletInfo(data) {
			this.walletWithdrawInfoShow = true;
			this.walletWithdrawInfoData = data;
		},
		onArrivalAmount() {
			this.walletWithdrawApplyData.walletWithdrawArrivalAmount = 0;
			if (parseFloat(this.walletWithdrawApplyData.walletWithdrawAmount) > 0) {
				this.walletWithdrawApplyData.walletWithdrawArrivalAmount = (parseFloat(this.walletWithdrawApplyData.walletWithdrawAmount) * 0.99).toFixed(2);
			}
		},
		onVideoUploadFail(err) {
			console.log(err);
		},
		onVideoUploadSuccess(file) {
			this.walletWithdrawApplyData.walletWithdrawBill = file;
		},
		onWalletTotalList() {
			apiCall(apiUrlEnums.agentWalletTotal, {}, false).then(res => {
				if (res.code === 0) {
					this.walletTotalList = res.list;
					this.walletTotalData = res.data;
				} else {
					this.$message.error(res.msg);
					return false;
				}
			});
		},
		onWalletWithdrawSizeChange(val) {
			this.walletWithdrawSearch.pageSize = val;
			this.onWalletWithdrawList();
		},
		onWalletWithdrawCurrentChange(val) {
			this.walletWithdrawSearch.page = val;
			this.onWalletWithdrawList();
		},
		onWalletWithdrawList() {
			apiCall(apiUrlEnums.agentWalletWithdrawList, this.walletWithdrawSearch, false).then(res => {
				if (res.code === 0) {
					this.walletWithdrawList = res.data.data;
					this.walletWithdrawTotal = res.data.total;
				} else {
					this.$message.error(res.msg);
					return false;
				}
			});
		},
		onWalletProfitSizeChange(val) {
			this.walletProfitSearch.pageSize = val;
			this.onWalletProfitList();
		},
		onWalletProfitCurrentChange(val) {
			this.walletProfitSearch.page = val;
			this.onWalletProfitList();
		},
		onWalletProfitList() {
			apiCall(apiUrlEnums.agentWalletProfitList, this.walletProfitSearch, false).then(res => {
				if (res.code === 0) {
					this.walletProfitList = res.data.data;
					this.walletProfitTotal = res.data.total;
				} else {
					this.$message.error(res.msg);
					return false;
				}
			});
		},
		onWalletWithdrawAccountList() {
			apiCall(apiUrlEnums.agentWalletWithdrawAccountList, this.walletProfitSearch, false).then(res => {
				if (res.code === 0) {
					this.walletWithdrawAccountList = res.data;
				} else {
					this.$message.error(res.msg);
					return false;
				}
			});
		},
		onWalletWithdrawApply() {
			this.onWalletWithdrawAccountList();
			this.walletWithdrawApplyShow = true;
			this.walletWithdrawApplyData = {
				walletWithdrawAmount: 0,
				walletWithdrawArrivalAmount: 0,
				walletWithdrawBank: "",
				walletWithdrawBill: "",
			};
		},
		onWalletWithdrawApplySubmit() {
			if (parseFloat(this.walletTotalData["agent_sure_withdrawal"]) < parseFloat(this.walletWithdrawApplyData.walletWithdrawAmount)) {
				this.$message.error("提现金额不能大于可提现金额！");
				return;
			}

			this.$refs["walletWithdrawApplyFrom"].validate((valid) => {
				if (valid) {
					apiCall(apiUrlEnums.agentWalletWithdrawApplySubmit, this.walletWithdrawApplyData, false).then(res => {
						if (res.code === 0) {
							this.onWalletTotalList();
							this.onWalletWithdrawList();
							this.$message.success(res.msg);
							this.walletWithdrawApplyShow = false;
						} else {
							this.$message.error(res.msg);
							return false;
						}
					});
				}
			});
		},
		onWalletWithdrawBank() {
			this.onBankList();
			this.onWalletWithdrawAccountList();
			this.walletWithdrawBankShow = true;
		},
		onBankList() {
			apiCall(apiUrlEnums.agentBankList, {}, false).then(res => {
				if (res.code === 0) {
					this.walletBankList = res.data;
				} else {
					this.$message.error(res.msg);
					return false;
				}
			});
		},
		onWalletWithdrawBankAdd() {
			this.walletWithdrawBankAddShow = true;
			this.walletWithdrawBankEditShow = false;
			this.walletWithdrawButtonFlg = true;
			this.walletWithdrawBankData = {
				bankId: "",
				bankAccount: "",
				bankUser: ""
			}
		},
		onWalletWithdrawBankAddSubmit() {
			this.$refs["walletWithdrawBankFrom"].validate((valid) => {
				if (valid) {
					apiCall(apiUrlEnums.agentWalletWithdrawBankAddSubmit, this.walletWithdrawBankData, false).then(res => {
						if (res.code === 0) {
							this.$message.success(res.msg);
							this.walletWithdrawBankAddShow = false;
							this.walletWithdrawBankEditShow = false;
							this.walletWithdrawButtonFlg = false;
							this.onWalletWithdrawAccountList();
						} else {
							this.$message.error(res.msg);
							return false;
						}
					});
				}
			});
		},
		onWalletWithdrawBankEdit(item) {
			this.walletWithdrawBankAddShow = false;
			this.walletWithdrawBankEditShow = true;
			this.walletWithdrawButtonFlg = true;
			this.walletWithdrawBankData = {
				bankAccountId: item.agent_withdraw_account_id,
				bankId: parseInt(item.agent_withdraw_account_bank_id),
				bankAccount: item.agent_withdraw_account_card,
				bankUser: item.agent_withdraw_account_hold
			}
		},
		onWalletWithdrawBankDel(bankAccountId) {
			this.$confirm("您确认要删除该账户吗?", "提示", { confirmButtonText: "确认", cancelButtonText: "取消", type: "warning" }).then(() => {
				apiCall(apiUrlEnums.agentWalletWithdrawBankDel, { bankAccountId: bankAccountId }, false).then(res => {
					if (res.code === 0) {
						this.$message.success(res.msg);
						this.onWalletWithdrawAccountList();
					} else {
						this.$message.error(res.msg);
						return false;
					}
				});
			});
		},
		onCaseTypeNamesFormat(row, column) {
			if (row[column.property]) {
				return row[column.property];
			}

			return "--"
		},
		onDateTimeFormat(row, column) {
			if (row[column.property]) {
				return this.$moment(row[column.property]).format('YYYY-MM-DD HH:mm:ss');
			}

			return "--";
		},
		onDateTimeFormatStr(data) {
			if (data) {
				return this.$moment(data).format('YYYY-MM-DD HH:mm:ss');
			}

			return "--";
		},
		onNumberToCurrencyFormat(row, column) {
			if (row[column.property]) {
				return formatUtils.toThousands(row[column.property]);
			}

			return "--"
		},
		onNumberToCurrency(data) {
			return formatUtils.toThousands(data);
		},
		onRoleFunc(funcId) {
			return baseUtils.getRoleFunc(funcId);
		}
	},
	created() {
		this.onWalletTotalList();
		this.onWalletWithdrawList();
		this.onWalletProfitList();
	},
};
</script>
